import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const DiseñoEditorialPage = () => {
    const data = useStaticQuery(graphql`
    query {
      editorialImage1: file(relativePath: { eq: "images/sections/diseno-grafico/editorial/que-es-diseno-editorial.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      editorialImage2: file(relativePath: { eq: "images/sections/diseno-grafico/editorial/experto-disenos-catalogos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      editorialImage3: file(relativePath: { eq: "images/sections/diseno-grafico/editorial/servicios-360-diseno-editorial-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Diseño Editorial"
                description="Agencia de Publicidad en Barcelona experta en diseño editorial destacando el diseño de catálogos para empresas."
            />

            <BannerPage
                title="Expertos en diseño editorial"
                text="Diseño de catálogos y revistas corporativas para empresas"
                color="pink"
            />

            <SectionTextImage
                title1="Diseño Editorial"
                title2="¿Qué es?"
                text={<Fragment>
                    El <strong>diseño editorial</strong> es la creación de contenido en todos sus formatos para nichos de mercado profesionales tales como el sector empresarial, técnico, institucional, científico, escoltar, académico, etc.<br /><br />
                    Las <strong>piezas editoriales</strong> más habituales son: los catálogos, manuales de empresas, brochures, presentaciones de empresa, flyers corporativos, revistas corporativas.
                </Fragment>}
                image={data.editorialImage1.childImageSharp.fluid}
                imageAlt="Diseño Editorial Barcelona - Agencia Diseño Editorial"
                color="pink"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Expertos en diseño "
                title2="de catálgos"
                text={<Fragment>
                    En IBX somos <strong>expertos en el diseño de catálogos para empresas</strong> ya que disponemos de todos los medios y canales para ofrecer la máxima calidad. <br /><br />
                    <ul className="text-left">
                        <li><strong>Elaboramos todo tipo de catálogos</strong>: de productos, técnicos, corporativos, etc.</li>
                        <li><strong>Catálogos en todos sus formatos</strong>:  para ser impresos o catálogos digitales.</li>
                        <li><strong>Catálogos para todo tipo de sectores</strong>: alimentación, cosmética y belleza, industrial, turístico, farmacéutico, sanitario, educación, etc.</li>
                    </ul>
                </Fragment>}
                image={data.editorialImage2.childImageSharp.fluid}
                imageAlt="Diseño Editorial Barcelona - Agencia Diseño Editorial"
                color="pink"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Servicio 360 "
                title2="en diseño editorial"
                text={<Fragment>
                    En IBX <strong>producimos cualquier publicación editorial</strong> para hacer despuntar tu empresa. Nos encargamos de todo el proceso de producción; desde su concepción, hasta su producción gráfica.<br /><br />
                    <ol class="text-left">
                        <li>Ideamos la línea gráfica del proyecto</li>
                        <li>Creamos el contenido textual y gráfico</li>
                        <li>Elaboramos y maquetamos la pieza editorial</li>
                        <li>Producimos el producto</li>
                    </ol>
                </Fragment>}
                image={data.editorialImage3.childImageSharp.fluid}
                imageAlt="Diseño Editorial Barcelona - Agencia Diseño Editorial"
                color="pink"
                containerTextRight={false}
            />

            <CallToAction
                color="pink"
                title="¿Necesitas crear un catálogo de tus productos o servicios?"
                text={<Fragment>
                    ¡Somos tu <strong>agencia de diseño editorial en Barcelona</strong>!
                </Fragment>}
                button="¿Maquetamos juntos?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default DiseñoEditorialPage
